export default class Modal {
    constructor() {
        this.eventBind();
    }

    open(e) {
        e.preventDefault();
        document.body.setAttribute("data-lock", "1");
        const modalID = this.el.getAttribute("data-modalid");
        const tgtmodal = document.querySelector(`.js-modal[data-modalid=${modalID}]`);
        tgtmodal && tgtmodal.setAttribute("data-active", "1");
    }

    close() {
        document.body.removeAttribute("data-lock");
        this.modals.forEach((el) => el.removeAttribute("data-active"));
    }

    eventBind() {
        const openbuttons = [...document.querySelectorAll(".js-modal_open")];
        const closebuttons = [...document.querySelectorAll(".js-modal_close")];
        const modals = [...document.querySelectorAll(".js-modal")];

        openbuttons.forEach((el) => {
            el.addEventListener("click", { el: el, handleEvent: this.open });
        });

        closebuttons.forEach((el) => {
            el.addEventListener("click", { modals: modals, handleEvent: this.close });
        });
    }

    resetEvent() {
        const openbuttons = [...document.querySelectorAll(".js-modal_open")];
        const closebuttons = [...document.querySelectorAll(".js-modal_close")];

        openbuttons.forEach((el) => {
            el.removeEventListener("click", this.open);
        });

        closebuttons.forEach((el) => {
            el.removeEventListener("click", this.close);
        });
    }
}
