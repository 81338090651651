export default class HierarchicalSelect {
    constructor(pageName) {
        this.pageName = pageName;
        this.storageName = pageName + "_select";
        this.selected = `/${pageName}/`;
        this.parentSelect = null;
        this.childSelect = null;
        this.init();
    }

    init() {
        //重複しないように設定
        if (!document.querySelector(`.hie_select[data-page="${this.pageName}"]`)) return;
        this.eventBind();
        this.restoreSelect();
        //console.log(`${this.pageName} HierarchicalSelect`);
    }

    restoreSelect() {
        const savedSelect = sessionStorage.getItem(this.storageName);
        const first = [...document.querySelectorAll(".hie_select__child")][0];
        first.setAttribute("data-active", "1");

        if (savedSelect) {
            const selected = JSON.parse(savedSelect);

            const parentSelect = document.querySelector(".hie_select__parent");
            const childSelect = [...document.querySelectorAll(".hie_select__child")];

            //先に親セレクトを設定する
            if (selected.parentSelect) {
                parentSelect.value = selected.parentSelect;
                this.parentSelect = selected.parentSelect;
            } else {
                //デフォルト血
                const defaultValue = [...parentSelect.querySelectorAll("option")][0].value;
                parentSelect.value = defaultValue;
                this.parentSelect = defaultValue;
            }

            if (selected.childSelect) {
                childSelect.forEach((item) => {
                    if (item.name === this.parentSelect) {
                        item.value = selected.childSelect;
                        item.setAttribute("data-active", "1");
                        this.childSelect = selected.childSelect;

                        //selected最終
                        const targetDom = item.querySelector(`option[value="${selected.childSelect}"]`);
                        this.selected = targetDom.getAttribute("data-url");
                    } else {
                        item.setAttribute("data-active", "0");
                    }
                });
            }
        }
    }

    eventBind() {
        const parentSelect = document.querySelector(".hie_select__parent");
        const childSelect = [...document.querySelectorAll(".hie_select__child")];
        const gobtn = document.querySelector(".hie_select__gobtn");

        parentSelect.addEventListener("change", (e) => {
            const targetDom = parentSelect.querySelector(`option[value="${e.target.value}"]`);
            this.selected = targetDom.getAttribute("data-url");
            this.parentSelect = e.target.value;

            childSelect.forEach((child) => {
                //optionの状態初期化
                const options = [...child.querySelectorAll("option")];
                child.value = options[0].value;

                if (child.name == e.target.value) {
                    child.setAttribute("data-active", "1");
                    this.childSelect = options[0].value;
                } else {
                    child.setAttribute("data-active", "0");
                }
            });
        });

        childSelect.forEach((item) => {
            item.addEventListener("change", (e) => {
                this.childSelect = e.target.value;
                const targetDom = item.querySelector(`option[value="${e.target.value}"]`);
                this.selected = targetDom.getAttribute("data-url");
            });
        });

        gobtn.addEventListener("click", () => {
            sessionStorage.setItem(this.storageName, JSON.stringify({ parentSelect: this.parentSelect, childSelect: this.childSelect }));
            location.href = this.selected;
        });
    }
}
